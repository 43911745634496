html {
  font-family: "Lucida Sans", sans-serif;
}
body {
  background-color: #ffffff;
}

.App {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}
.APPLogo {
  width: 130px;
  height: 33px;
  border-radius: 0%;
}
.APPLogoH1{
  display: inherit;
  font-size: 1.7em;
  font-variant: all-petite-caps;
  color: #d2d3d5;
}


.App header {
  background-color: #ffffff;
  height: 10vh;
  min-height: 50px;
  color: #537ec1;
  position: fixed;
  width: 100%;
  max-width: 500px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
 
}

main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 50px 20px rgb(24, 23, 23);
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #537ec1;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 500px;
  display: flex;
  font-size: 1.5rem;
}

form button {
  width: 20%;
  background-color: #537ec1;
}


input {
  line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    background: rgb(255, 255, 255);
    color: #537ec1;
    outline: none;
    border: none;
    padding: 0 10px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.sign-out {
  border-radius: 10px;
  padding: 10px 10px;
  background-color: #537ec1;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

.sign-in-Before{
  min-height: 35vh;
}


ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background:#26ac52;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:480px;
    }
    .App header {
      width: 100%;
      max-width: 480px;
    }
    form{
      width: 100%;
      max-width: 480px;
    }
    .sign-in {
      max-width: 400px;
    }
    p {
      max-width: 480px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:480px;
    }
    .App header {
      width: 100%;
      max-width: 480px;
    }
    form{
      width: 100%;
      max-width: 480px;
    }
    .sign-in {
      max-width: 400px;
    }
    p {
      max-width: 480px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:480px;
    }
    .App header {
      width: 100%;
      max-width: 480px;
    }
    form{
      width: 100%;
      max-width: 480px;
    }
    .sign-in {
      max-width: 400px;
    }
    p {
      max-width: 480px;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:568px;
    }
    .App header {
      width: 100%;
      max-width: 568px;
    }
    form{
      width: 100%;
      max-width: 568px;
    }
    .sign-in {
      max-width: 568px;
    }
    p {
      max-width: 568px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:568px;
    }
    .App header {
      width: 100%;
      max-width: 568px;
    }
    form{
      width: 100%;
      max-width: 568px;
    }
    .sign-in {
      max-width: 568px;
    }
    p {
      max-width: 568px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:568px;
    }
    .App header {
      width: 100%;
      max-width: 568px;
    }
    form{
      width: 100%;
      max-width: 568px;
    }
    .sign-in {
      max-width: 568px;
    }
    p {
      max-width: 568px;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .App {
      width:100%;
      max-width:667px;
    }
    .App header {
      width: 100%;
      max-width: 667px;
    }
    form{
      width: 100%;
      max-width: 667px;
    }
    .sign-in {
      max-width: 667px;
    }
    p {
      max-width: 667px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

    .App {
      width:100%;
      max-width:667px;
    }
    .App header {
      width: 100%;
      max-width: 667px;
    }
    form{
      width: 100%;
      max-width: 667px;
    }
    .sign-in {
      max-width: 667px;
    }
    p {
      max-width: 667px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    .App {
      width:100%;
      max-width:667px;
    }
    .App header {
      width: 100%;
      max-width: 667px;
    }
    form{
      width: 100%;
      max-width: 667px;
    }
    .sign-in {
      max-width: 667px;
    }
    p {
      max-width: 667px;
    }

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .App {
      width:100%;
      max-width:736px;
    }
    .App header {
      width: 100%;
      max-width: 736px;
    }
    form{
      width: 100%;
      max-width: 736px;
    }
    .sign-in {
      max-width: 736px;
    }
    p {
      max-width: 736px;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .App {
      width:100%;
      max-width:736px;
    }
    .App header {
      width: 100%;
      max-width: 736px;
    }
    form{
      width: 100%;
      max-width: 736px;
    }
    .sign-in {
      max-width: 736px;
    }
    p {
      max-width: 736px;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .App {
      width:100%;
      max-width:736px;
    }
    .App header {
      width: 100%;
      max-width: 736px;
    }
    form{
      width: 100%;
      max-width: 736px;
    }
    .sign-in {
      max-width: 736px;
    }
    p {
      max-width: 736px;
    }

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .App {
      width:100%;
      max-width:812px;
    }
    .App header {
      width: 100%;
      max-width:812px;
    }
    form{
      width: 100%;
      max-width:812px;
    }
    .sign-in {
      max-width:812px;
    }
    p {
      max-width:812px;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .App {
      width:100%;
      max-width:812px;
    }
    .App header {
      width: 100%;
      max-width:812px;
    }
    form{
      width: 100%;
      max-width:812px;
    }
    .sign-in {
      max-width:812px;
    }
    p {
      max-width:812px;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .App {
      width:100%;
      max-width:812px;
    }
    .App header {
      width: 100%;
      max-width:812px;
    }
    form{
      width: 100%;
      max-width:812px;
    }
    .sign-in {
      max-width:812px;
    }
    p {
      max-width:812px;
    }

}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }

}

/* Portrait */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 2) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 2) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Portrait */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: portrait) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: landscape) {
    .App {
      width:100%;
      max-width:640px;
    }
    .App header {
      width: 100%;
      max-width:640px;
    }
    form{
      width: 100%;
      max-width:640px;
    }
    .sign-in {
      max-width:640px;
    }
    p {
      max-width:640px;
    }
}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 384px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 2) {

}

/* Portrait */
@media screen 
  and (device-width: 384px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 384px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 3) {

}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: landscape) {

}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 4) {

}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 592px) 
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: landscape) {

}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 480px) 
  and (device-height: 800px) {

}

/* Portrait */
@media screen 
  and (device-width: 480px) 
  and (device-height: 800px)  
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 480px) 
  and (device-height: 800px) 
  and (orientation: landscape) {

}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:1024px;
    }
    .App header {
      width: 100%;
      max-width:1024px;
    }
    form{
      width: 100%;
      max-width:1024px;
    }
    .sign-in {
      max-width:1024px;
    }
    p {
      max-width:1024px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:1024px;
    }
    .App header {
      width: 100%;
      max-width:1024px;
    }
    form{
      width: 100%;
      max-width:1024px;
    }
    .sign-in {
      max-width:1024px;
    }
    p {
      max-width:1024px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:1024px;
    }
    .App header {
      width: 100%;
      max-width:1024px;
    }
    form{
      width: 100%;
      max-width:1024px;
    }
    .sign-in {
      max-width:1024px;
    }
    p {
      max-width:1024px;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:1024px;
    }
    .App header {
      width: 100%;
      max-width:1024px;
    }
    form{
      width: 100%;
      max-width:1024px;
    }
    .sign-in {
      max-width:1024px;
    }
    p {
      max-width:1024px;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .App {
      width:100%;
      max-width:834px;
    }
    .App header {
      width: 100%;
      max-width:834px;
    }
    form{
      width: 100%;
      max-width:834px;
    }
    .sign-in {
      max-width:834px;
    }
    p {
      max-width:834px;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- Galaxy Tab 2 ----------- */

/* Portrait and Landscape */
@media 
  (min-device-width: 800px) 
  and (max-device-width: 1280px) {

}

/* Portrait */
@media 
  (max-device-width: 800px) 
  and (orientation: portrait) { 

}

/* Landscape */
@media 
  (max-device-width: 1280px) 
  and (orientation: landscape) { 

}

/* ----------- Galaxy Tab S ----------- */

/* Portrait and Landscape */
@media 
  (min-device-width: 800px) 
  and (max-device-width: 1280px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media 
  (max-device-width: 800px) 
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Landscape */
@media 
  (max-device-width: 1280px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* ----------- Nexus 7 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 601px) 
  and (device-height: 906px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) {

}

/* Portrait */
@media screen 
  and (device-width: 601px) 
  and (device-height: 906px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) 
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 601px) 
  and (device-height: 906px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) 
  and (orientation: landscape) {

}

/* ----------- Nexus 9 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 1536px) 
  and (device-height: 2048px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) {

}

/* Portrait */
@media screen 
  and (device-width: 1536px) 
  and (device-height: 2048px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) 
  and (orientation: portrait) {

}

/* Landscape */
@media screen 
  and (device-width: 1536px) 
  and (device-height: 2048px) 
  and (-webkit-min-device-pixel-ratio: 1.331) 
  and (-webkit-max-device-pixel-ratio: 1.332) 
  and (orientation: landscape) {

}

/* ----------- Kindle Fire HD 7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1280px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1280px) 
  and (-webkit-min-device-pixel-ratio: 1.5) 
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1280px) 
  and (-webkit-min-device-pixel-ratio: 1.5) 
  and (orientation: landscape) {

}

/* ----------- Kindle Fire HD 8.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1.5) 
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1.5) 
  and (orientation: landscape) {

}

/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
}

/* ----------- Moto 360 Watch ----------- */
@media 
  (max-device-width: 218px)
  and (max-device-height: 281px) { 

}